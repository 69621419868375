.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.unit-icon {
  width: 46px;
  height: 46px;
  background-image: url('../../assets/units.png');
}

.unit-image{
  justify-content: center;
  display: flex;
}
.pikeman {
  background-position: 0px -46px;
}

.pikemanpor {
  background-position: -552px -46px;
}

.pikemanpol {
  background-position: -46px -46px;
}

.pikemantur {
  background-position: -138px -46px;
}

.pikemanspa {
  background-position: -505px -46px;
}

.pikemanrus {
  background-position: -92px -46px;
}

.pikemanswi {
  background-position: -598px -46px;
}

.pikemansco {
  background-position: -415px -415px;
}

.roundshier {
  background-position: -184px -46px;
}

.pikeman18 {
  background-position: -276px -46px;
}

.pikeman18swe {
  background-position: -322px -46px;
}

.lightinfantry {
  background-position: -230px -46px;
}

.musketeer {
  background-position: 0 -92px;
}

.musketeerpol {
  background-position: -46px -92px;
}

.musketeeraus {
  background-position: -138px -92px;
}

.musketeerspa {
  background-position: -184px -92px;
}

.musketeernet {
  background-position: -414px -92px;
}

.musketeersco {
  background-position: -460px -46px;
}

.musketeer18 {
  background-position: 0px -138px
}

.musketeer18den {
  background-position: -276px -138px;
}

.musketeer18sax {
  background-position: -368px -138px;
}

.musketeer18pru {
  background-position: -230px -138px;
}

.musketeer18bav {
  background-position: -368px -46px;
}

.strelet {
  background-position: -92px -92px;
}

.serdiuk {
  background-position: -230px -92px;
}

.gauduk {
  background-position: -505px -92px;
}

.jannisary {
  background-position: -276px -92px;
}

.archertur {
  background-position: -368px -92px;
}

.archer {
  background-position: -323px -92px;
}

.archersco {
  background-position: -460px -92px;
}

.swordsmansco {
  background-position: -460px -415px;
}

.pandur {
  background-position: -46px -138px
}

.grenadier {
  background-position: -184px -138px;
}

.officer {
  background-position: -0px -184px;
}

.drummer {
  background-position: -184px -184px;
}

.officer18 {
  background-position: -138px -184px;
}

.drummer18 {
  background-position: -368px -184px;
}

.priest {
  background-position: -0px -230px;
}

.chasseur {
  background-position: -92px -138px
}

.highlander {
  background-position: -138px -138px
}

.bagpiper {
  background-position: -322px -184px;
}

.officerrus {
  background-position: -46px -184px;
}

.drummerrus {
  background-position: -230px -184px;
}

.pope {
  background-position: -46px -230px;
}

.officertur {
  background-position: -92px -184px;
}

.drummertur {
  background-position: -276px -184px;
}

.mullah {
  background-position: -92px -230px;
}

.officersco {
  background-position: -413px -184px;
}

.pandurhun {
  background-position: -552px -138px
}

.grenadierhun {
  background-position: -598px -138px
}

.jagerswi {
  background-position: -645px -138px
}

.grenadierbav {
  background-position: -413px -46px;
}

.grenadiersax {
  background-position: -413px -138px
}

.padre {
  background-position: -505px -230px;
}

.jagerpor {
  background-position: -505px -138px;
}

.grenadierden {
  background-position: -322px -138px;
}

.grenadierpru {
  background-position: -460px -138px;
}


.croat {
  background-position: -0px -276px;
}

.hussar {
  background-position: -138px -276px;
}

.reiter {
  background-position: -0px -322px;
}

.cuirassier {
  background-position: -415px -322px;
}

.dragoon {
  background-position: -0px -368px;
}

.dragoon18 {
  background-position: -138px -368px;
}

.kingmusketeer {
  background-position: -46px -368px;
}

.cossackdon {
  background-position: -138px -322px;
}

.vityaz {
  background-position: -92px -322px;
}

.cossacksich {
  background-position: -92px -276px;
}

.cossackregister {
  background-position: -230px -322px;
}

.hetman {
  background-position: -276px -322px;
}

.wingedhussar {
  background-position: -46px -276px;
}

.reiterpol {
  background-position: -184px -322px;
}

.dragoonpol {
  background-position: -322px -368px;
}

.reiterswe {
  background-position: -46px -322px;
}

.hackapell {
  background-position: -230px -276px;
}

.hussarpru {
  background-position: -184px -276px;
}

.spakh {
  background-position: -322px -322px;
}

.tatar {
  background-position: -92px -368px;
}

.sipahi {
  background-position: -505px -322px;
}

.mameluke {
  background-position: -368px -322px;
}

.dragoon18net {
  background-position: -368px -368px;
}

.dragoon18pie {
  background-position: -415px -368px;
}

.guardcavalrysax {
  background-position: -460px -322px;
}

.hussarhun {
  background-position: -368px -276px;
}

.lightcavalry {
  background-position: -276px -368px;
}

.hussarswi {
  background-position: -415px -276px;
}

.lancersco {
  background-position: -322px -276px;
}

.raidersco {
  background-position: -276px -276px;
}

.peasco {
  background-position: -322px 0px;
}

.peapol {
  background-position: -230px 0px;
}

.peatur {
  background-position: -276px 0;
}

.pearus {
  background-position: -138px 0px;
}

.peaukr {
  background-position: -183px 0px;
}

.peaaus {
  background-position: 0px 0px;
}

.peaeng {
  background-position: -46px 0px;
}

.peaspa {
  background-position: -92px 0px;
}
.lightinfantrydip{
  background-position: -0px -414px;
}
.roundshierdip{
  background-position: -46px -414px;
}
.grenadierdip{
  background-position: -138px -414px;
}
.archerdip{
  background-position: -92px -414px;
}
.cossacksichdip{
  background-position: -184px -414px;
}
.dragoon18dip{
  background-position: -230px -414px;
}
.archerturdip{
  background-position: -322px -414px;
}
.lightcavalrydip{
  background-position: -276px -414px;
}
.custom-select {
  position: relative;
}

.selected-option {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  cursor: pointer;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.option {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
}

.option img {
  width: 30px; /* Adjust image size as needed */
  height: 30px;
  margin-right: 10px;
}

.option span {
  font-size: 14px;
}
.wrapper-upgrades{
  display: flex;
  flex-direction: row;
}
.upgrade-wrapper img{
  border: 1px solid #ccc;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.text-center{
  text-align: center;
}
.custom-select {
  position: relative;
  display: inline-block;
}

.selected-option {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Hide scrollbar for WebKit browsers */
.options::-webkit-scrollbar {
  display: none;
}
.option {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.option img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.option:hover {
  background-color: #f0f0f0;
}


.imgdark{
  filter: brightness(0.5);
}
.progress-bar-container {
  position: relative;
  width: 43px;
  height: 5px;
  background-color: #f0f0f0; /* Background color of progress bar container */
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 50;
  width: 43px;
  height: 100%;
  background-color: #cceeff; /* Progress color */
  animation: progress-animation 520ms linear forwards; /* Animation */
}

@keyframes progress-animation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.unit-upgrade-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.academy-wrapper, .blacksmith-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  position: relative;
  text-align: center;
}

.wrapper-protection span {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
}

.wrapper-protection img {
  max-width: 100px; /* Set a maximum width for the images */
  margin-right: 5px; /* Reduce margin between img and p */
}
.weapon{
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.hp-elem{
  border-bottom: 2px solid red;
}
.wrapper-main{
display: flex;
flex-direction: row;
gap: 5px;
justify-content: center;
align-items: center;
}
.wrapper_resource{
display:grid;
grid-template-columns: repeat(3, 1fr);
}
.wrapper-profile{
display: flex;
flex-direction: row;
gap: 5px;
justify-content: center;
align-items: center;
}
.img-container {
display: flex;
justify-content: center; /* Horizontally center the content */
align-items: center; /* Vertically center the content */
}

.img-container img {
/* Optional styles for the shield image */
height: auto; /* Maintain aspect ratio */
margin-left: 5px;
}
.img-upgrade {
transition: opacity 0.3s ease, box-shadow 0.3s ease;
box-shadow: 0 4px 8px rgba(29, 231, 11, 0.7);
}

.img-upgrade.disabled {
opacity: 0.5;
cursor: not-allowed;
pointer-events: none;
box-shadow: 0 4px 8px rgba(255, 0, 0, 0.7);
}
.img-upgrade-wrapper{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin: 10px 0;

}
.img-upgrade{
cursor: pointer;

}
.progress-bar-container {
width: 100%;
border-radius: 5px;
overflow: hidden;
margin-top: 10px;
height: 5px;
}

.progress-bar {
height: 100%;
background-color: #f5f5f5;
width: 0%;
}

.progress-bar.animate {
animation: progress 2s forwards, changeColor 2s linear;
}

@keyframes progress {
to {
  width: 100%;
}
}

@keyframes changeColor {
0% {
  background-color: #f5f5f5;
}
100% {
  background-color: #f5bd06;
}
}
.wrapper-unit{
display: flex;
flex-direction: column;
gap: 5px;
align-items: center;
}
.wrapper-select {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 4px; /* Space between NationSelect and UnitSelect */
}

.wrapper-select select {
padding: 2px;
height: 30px;
border-radius: 4px;
}
.custom-select-unit{
position: relative;
}
.custom-selected-unit{
display: flex;
flex-direction: row;
align-items: center;
gap: 2px;
cursor: pointer;
position: relative;
}

.custom-dropdown-unit{
height: 300px;
overflow-y: scroll;
overflow-x: hidden;
cursor: pointer;
position: absolute;
top: 30px;
z-index: 2;
background: #f5bd06;
}
.custom-dropdown-unit::-webkit-scrollbar-track {
background: #f5bd06; /* Background of the scrollbar track */
}
.custom-dropdown-unit::-webkit-scrollbar {
width: 4px; /* Width of the scrollbar */
}
/* Scrollbar thumb */
.custom-dropdown-unit::-webkit-scrollbar-thumb {
background-color: #361d08; /* Color of the scrollbar thumb */
border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}
/* Scrollbar thumb on hover */
.custom-dropdown-unit::-webkit-scrollbar-thumb:hover {
background-color: #b48804; /* Color of the scrollbar thumb on hover */
}
.dropdown-open{
display: none;
}
.custom-dropdown-unit .dropdown-item{
cursor: pointer;
display: flex;
flex-direction: row;
align-items: center;
margin-bottom: 2px;
position: relative;
padding: 4px;
}
.dropdown-open{
display: none;
}

.tooltip-unit {
visibility: hidden;
width: auto;
max-width: 200px;
background-color: rgba(0, 0, 0, 0.8);
color: #fff;
text-align: center;
border-radius: 4px;
padding: 5px 10px;
position: absolute;
z-index: 1;
bottom: 100%; /* Position above the selected unit */
left: 50%;
transform: translateX(-50%);
margin-bottom: 8px; /* Space between tooltip and selected unit */
opacity: 0;
transition: opacity 0.3s ease;
}

.tooltip-unit::after {
content: '';
position: absolute;
top: 100%; /* Arrow at the bottom of tooltip */
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

/* Show tooltip when hovering over the custom-selected-unit */
.custom-selected-unit:hover .tooltip-unit {
visibility: visible;
opacity: 1;
}

.label-minutes{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 5px;
padding: 5px;
border-radius: 4px
}
.crosfire{
position: fixed;
top: 50%;
left: 50%;
}